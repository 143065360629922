import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from "components/GlobalContext";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ILabRequestTemplateInitResult } from "models/ILabRequestTemplateInitResult";
import ILabRequestTemplate, { ILabRequestTemplateCreateOrUpdate } from "models/sql/ILabRequestTemplate";
import LabRequestTemplateService from "services/LabRequestTemplateService";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";

interface ILabRequestTemplateProps {
    baseData?: ILabRequestTemplateInitResult;
}

const LabRequestTemplateDetail = (props: ILabRequestTemplateProps) => {
    const globalContext = useContext(GlobalContext);
    const { id }: any = useParams();
    const [template, setTemplate] = useState<ILabRequestTemplate>();
    const navigate = useNavigate();
    const [hasFormDataChanged, setHasFormDataChanged] = useState(false);
    const [isCustomWorkflowValid, setIsCustomWorkflowValid] = useState(true);
    const [customWorkflowErrorLineNumber, setCustomWorkflowErrorLineNumber] = useState<number | undefined>();
    const [isTemplateValid, setTemplateIsValid] = useState(true);
    const [templateErrorLineNumber, setTemplateErrorLineNumber] = useState<number | undefined>();
    const [state, setState] = useState("loading");

    useEffect(
        () => {
            (async () => {
                globalContext.setLoading(true);
                setState("loading");

                const accessToken = await globalContext.getAccessTokenAsync();
                const result = await LabRequestTemplateService.GetAsync(accessToken, id);

                if (result.record) {
                    setTemplate(result.record);
                }
                else {
                    globalContext.showErrorMessage("Unable to load Template data");
                }

                setState("idle");
                globalContext.setLoading(false);
            })();
        }, [id]);

    const deleteAsync = async () => {
        if (template && window.confirm("Are you SURE you want to delete this?")) {
            globalContext.setLoading(true);
            setState("loading");
            const accessToken = await globalContext.getAccessTokenAsync();
            await LabRequestTemplateService.DeleteAsync(accessToken, template.labRequestTemplateId);
            setState("idle");
            globalContext.setLoading(false);
            navigate("/FormTemplates");
        }
    };

    const cloneAsync = async () => {
        var newTitle = window.prompt("New Form Title?", `Copy of ${template?.title}`);

        if (newTitle && template && template.labRequestTemplateId) {
            globalContext.setLoading(true);
            setState("loading");
            const accessToken = await globalContext.getAccessTokenAsync();
            await LabRequestTemplateService.CloneAsync(accessToken, template.labRequestTemplateId, newTitle);
            setState("idle");
            globalContext.setLoading(false);
            navigate("/FormTemplates");
        }
    };

    const submitFormAsync = async () => {
        if (template) {
            globalContext.setLoading(true);
            setState("loading");
            const accessToken = await globalContext.getAccessTokenAsync();

            const data: ILabRequestTemplateCreateOrUpdate = {
                labRequestTemplateId: template.labRequestTemplateId,
                active: template.active,
                definitionJson: template.definitionJson,
                description: template.description.replaceAll('"', '\"'),
                lastUpdated: (new Date).toISOString(),
                showInDirectory: template.showInDirectory,
                title: template.title,
                accessKey: template.accessKey,
                assignTo: template.assignTo,
                graphicUrl: template.graphicUrl,
                contactCompleteMessage: template.contactCompleteMessage.replaceAll('"', '\"'),
                contactInProgressMessage: template.contactInProgressMessage.replaceAll('"', '\"'),
                contactThankYouMessage: template.contactThankYouMessage.replaceAll('"', '\"'),
                categoryId: template.categoryId,
                subCategoryId: template.subCategoryId,
                fromEmail: template.fromEmail,
                defaultInternalNotes: template.defaultInternalNotes,
                internalFormId: template.internalFormId,
                version: template.version,
                acceptsAttachments: template.acceptsAttachments,
                formNumber: template.formNumber,
                notificationFrequency: template.notificationFrequency,
                includeContentInSubmitNotification: template.includeContentInSubmitNotification,
                advancedBackfillWorkflow: template.advancedBackfillWorkflow,
                submitRedirectSpeed: template.submitRedirectSpeed,
                submitRedirectUrl: template.submitRedirectUrl,
                advancedBackfillDescription: template.advancedBackfillDescription,
                advancedBackfillThankYouMessage: template.advancedBackfillThankYouMessage,
                backfillCodeTitle: template.backfillCodeTitle,
                backfillSubmitTitle: template.backfillSubmitTitle,
                submitTitle: template.submitTitle,
                contactExternalIssueMessage: template.contactExternalIssueMessage,
                attachmentFileSizeMax: template.attachmentFileSizeMax,
                attachmentFileTypes: template.attachmentFileTypes,
                customSubmissionDetailContent: template.customSubmissionDetailContent,
                customWorkflowDataJson: template.customWorkflowDataJson,
                shortUrl: template.shortUrl,
                grouping: template.grouping,
                includeContentInThankYouEmail: template.includeContentInThankYouEmail
            };

            if (hasFormDataChanged) {
                await LabRequestTemplateService.IncrementalUpdateAsync(accessToken, data);
            }
            else {
                await LabRequestTemplateService.CreateOrUpdateAsync(accessToken, data);
            }

            setState("idle");
            globalContext.setLoading(false);
            navigate("/FormTemplates");
        }
    };

    useEffect(
        () => {
            if (state !== "loading") {
                setIsCustomWorkflowValid(true);
                setCustomWorkflowErrorLineNumber(undefined);

                if (template && template.customWorkflowDataJson) {
                    try {
                        JSON.parse(template.customWorkflowDataJson);
                    }
                    catch (error: any) {
                        const lineMatch = error.message.match(/at position (\d+)/);
                        if (lineMatch && lineMatch.length > 0) {
                            const position = parseInt(lineMatch[1], 10);
                            const lines = template.customWorkflowDataJson.slice(0, position).split('\n');
                            const lineNumber = lines.length;

                            if (lineNumber) {
                                setCustomWorkflowErrorLineNumber(lineNumber);
                            }
                        }

                        setIsCustomWorkflowValid(false);
                    }
                }

                setTemplateIsValid(true);
                setTemplateErrorLineNumber(undefined);

                if (template && template.definitionJson) {
                    try {
                        JSON.parse(template.definitionJson);
                    }
                    catch (error: any) {
                        const lineMatch = error.message.match(/at position (\d+)/);
                        if (lineMatch && lineMatch.length > 0) {
                            const position = parseInt(lineMatch[1], 10);
                            const lines = template.definitionJson.slice(0, position).split('\n');
                            const lineNumber = lines.length;

                            if (lineNumber) {
                                setTemplateErrorLineNumber(lineNumber);
                            }
                        }

                        setTemplateIsValid(false);
                    }
                }
            }
        },
        [template, state]);

    if (template) {
        return (
            <>
                <Row>
                    <Col>
                        <Card className="mb-2 mt-2">
                            <Card.Body>
                                <Card.Title>
                                    <h1 style={{ padding: 0, margin: 0 }}>{template.title}</h1>
                                    <strong>
                                        {
                                            template.shortUrl ?
                                                <a href={template.shortUrl} target="_blank" rel="noreferrer">{template.shortUrl}</a> :
                                                <a href={`/public/form?form=${template.formNumber}`} target="_blank">{`/public/form?form=${template.formNumber}`}</a>
                                        }<br /><br />
                                        General Settings</strong>
                                </Card.Title>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Form Title</Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, title: e.target.value });
                                                        }
                                                    }
                                                    value={template.title}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Header Graphic (Empty = hidden)</Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, graphicUrl: e.target.value });
                                                        }
                                                    }
                                                    value={template.graphicUrl}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Form Description</Form.Label>
                                            <AceEditor
                                                height="150px"
                                                width="100%"
                                                fontSize={14}
                                                mode="html"
                                                theme="github"
                                                onChange={(code: any) => {
                                                    setTemplate({ ...template, description: code })
                                                }}
                                                name="templatedescription"
                                                value={template.description}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Default Internal Notes</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                onChange={
                                                    (e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setTemplate({ ...template, defaultInternalNotes: e.target.value })
                                                    }
                                                }
                                                value={(template?.defaultInternalNotes || "")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Emails to be notified on submission<br /><i style={{ color: "red" }}>Comma seperated</i></Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, assignTo: e.target.value });
                                                        }
                                                    }
                                                    value={template.assignTo}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Automated correspondence 'From' email<br /><i style={{ color: "red" }}>Note: Must be allowed as a SMTP source!</i></Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, fromEmail: e.target.value });
                                                        }
                                                    }
                                                    value={template.fromEmail}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Internal Notification Frequency?</Form.Label>
                                            {
                                                <Form.Select
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            setTemplate({ ...template, notificationFrequency: e.target.value });
                                                        }
                                                    }
                                                    value={template.notificationFrequency.toString()}>
                                                    <option value="0">Immediate</option>
                                                    <option value="1">Daily</option>
                                                </Form.Select>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Active?</Form.Label>
                                            {
                                                <Form.Select
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            setTemplate({ ...template, active: e.target.value === "true" });
                                                        }
                                                    }
                                                    value={template.active.toString()}>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Form.Select>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Include contents in submission notification?</Form.Label>
                                            {
                                                <Form.Select
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            setTemplate({ ...template, includeContentInSubmitNotification: e.target.value === "true" });
                                                        }
                                                    }
                                                    value={template.includeContentInSubmitNotification.toString()}>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Form.Select>
                                            }
                                        </Form.Group>
                                    </Col>

                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Include contents in thankyou email?</Form.Label>
                                            {
                                                <Form.Select
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            setTemplate({ ...template, includeContentInThankYouEmail: e.target.value === "true" });
                                                        }
                                                    }
                                                    value={template.includeContentInThankYouEmail ? "true" : "false"}>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Form.Select>
                                            }
                                        </Form.Group>
                                    </Col>

                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Directory order #?<br /><i style={{ color: "red" }}>(0 = hidden)</i></Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, showInDirectory: parseInt(e.target.value) });
                                                        }
                                                    }
                                                    value={template.showInDirectory}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>
                                                Form Number <i><strong>({template.formNumber} Rev. {template.version})</strong></i><br />
                                                <i style={{ color: "red", fontWeight: "bold" }}>You probably shouldn't change this! </i>
                                            </Form.Label>

                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, formNumber: e.target.value });
                                                        }
                                                    }
                                                    value={template.formNumber}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Access Key?<br /><i style={{ color: "red" }}>(Empty = public)</i></Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, accessKey: e.target.value });
                                                        }
                                                    }
                                                    value={template.accessKey}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Category</Form.Label>
                                            {
                                                <Form.Select
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            setTemplate({ ...template, categoryId: parseInt(e.target.value) });
                                                        }
                                                    }
                                                    value={template.categoryId}>
                                                    {
                                                        props.baseData?.categoryNames.map(
                                                            (name, index) => <option value={index}>{name}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Grouping</Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, grouping: e.target.value });
                                                        }
                                                    }
                                                    value={template.grouping}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Submission Redirect seconds</Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, submitRedirectSpeed: parseInt(e.target.value) | 0 });
                                                        }
                                                    }
                                                    value={template.submitRedirectSpeed}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Submission Redirect URL<br /><i style={{ color: "red" }}>(Empty = do not redirect)</i></Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, submitRedirectUrl: e.target.value });
                                                        }
                                                    }
                                                    value={template.submitRedirectUrl}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Submit Button Title</Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, submitTitle: e.target.value });
                                                        }
                                                    }
                                                    value={template.submitTitle}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Contact Thank You Message</Form.Label>
                                            <AceEditor
                                                height="150px"
                                                width="100%"
                                                fontSize={14}
                                                mode="html"
                                                theme="github"
                                                onChange={(code: any) => {
                                                    setTemplate({ ...template, contactThankYouMessage: code })
                                                }}
                                                name="templatecontactThankYouMessage"
                                                value={template.contactThankYouMessage}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Contact In Progress Message</Form.Label>
                                            <AceEditor
                                                height="150px"
                                                width="100%"
                                                fontSize={14}
                                                mode="html"
                                                theme="github"
                                                onChange={(code: any) => {
                                                    setTemplate({ ...template, contactInProgressMessage: code })
                                                }}
                                                name="templatecontactInProgressMessage"
                                                value={template.contactInProgressMessage}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Contact Complete Message</Form.Label>
                                            <AceEditor
                                                height="150px"
                                                width="100%"
                                                fontSize={14}
                                                mode="javascript"
                                                theme="github"
                                                onChange={(code: any) => {
                                                    setTemplate({ ...template, contactCompleteMessage: code })
                                                }}
                                                name="templatecontactCompleteMessage"
                                                value={template.contactCompleteMessage}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Contact External Issue Message</Form.Label>
                                            <AceEditor
                                                height="150px"
                                                width="100%"
                                                fontSize={14}
                                                mode="html"
                                                theme="github"
                                                onChange={(code: any) => {
                                                    setTemplate({ ...template, contactExternalIssueMessage: code })
                                                }}
                                                name="templatecontactExternalIssueMessage"
                                                value={template.contactExternalIssueMessage}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Accepts Attachments?</Form.Label>
                                            {
                                                <Form.Select
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            setTemplate({ ...template, acceptsAttachments: e.target.value === "true" });
                                                        }
                                                    }
                                                    value={template.acceptsAttachments.toString()}>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Form.Select>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Attachment File Types?<br /><i style={{ color: "red" }}>(Empty = any)</i></Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, attachmentFileTypes: e.target.value });
                                                        }
                                                    }
                                                    value={template.attachmentFileTypes}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Attachment File Size Max?<br /><i style={{ color: "red" }}>(0 = any size)</i></Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, attachmentFileSizeMax: parseInt(e.target.value) });
                                                        }
                                                    }
                                                    value={template.attachmentFileSizeMax}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Force 'Backfill' use?</Form.Label>
                                            {
                                                <Form.Select
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            setTemplate({ ...template, advancedBackfillWorkflow: e.target.value === "true" });
                                                        }
                                                    }
                                                    value={template.advancedBackfillWorkflow.toString()}>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Form.Select>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Backfill Code Title?</Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, backfillCodeTitle: e.target.value });
                                                        }
                                                    }
                                                    value={template.backfillCodeTitle}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Backfill Submit Button Title</Form.Label>
                                            {
                                                <Form.Control
                                                    type="text"
                                                    onChange={
                                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            setTemplate({ ...template, backfillSubmitTitle: e.target.value });
                                                        }
                                                    }
                                                    value={template.backfillSubmitTitle}
                                                />
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Create Backfill Code Form Description</Form.Label>
                                            <AceEditor
                                                height="150px"
                                                width="100%"
                                                fontSize={14}
                                                mode="html"
                                                theme="github"
                                                onChange={(code: any) => {
                                                    setTemplate({ ...template, advancedBackfillDescription: code })
                                                }}
                                                name="advancedBackfillDescription"
                                                value={template.advancedBackfillDescription || ""}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Create Backfill Code Thank You Message</Form.Label>
                                            <AceEditor
                                                height="150px"
                                                width="100%"
                                                fontSize={14}
                                                mode="html"
                                                theme="github"
                                                onChange={(code: any) => {
                                                    setTemplate({ ...template, advancedBackfillThankYouMessage: code })
                                                }}
                                                name="advancedBackfillThankYouMessage"
                                                value={template.advancedBackfillThankYouMessage || ""}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="align-items-end">
                    <Col>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>
                                    <strong>Custom Workflow Definition</strong><br />
                                    <small><strong><i>Overrides default statuses and contact messaging!</i></strong></small>
                                </Card.Title>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <AceEditor
                                                style={{ border: !isCustomWorkflowValid ? "3px solid red" : "1px solid black" }}
                                                width="100%"
                                                fontSize={14}
                                                mode="html"
                                                theme="github"
                                                onChange={(code: any) => {
                                                    setTemplate({ ...template, customWorkflowDataJson: code })
                                                }}
                                                name="customWorkflowDataJson"
                                                value={template.customWorkflowDataJson}
                                            />
                                            {
                                                !isCustomWorkflowValid &&
                                                <Form.Text style={{ color: "red" }}>
                                                    {
                                                        customWorkflowErrorLineNumber &&
                                                        `The JSON has an Error on or near line ${customWorkflowErrorLineNumber}`
                                                    }
                                                    {
                                                        !customWorkflowErrorLineNumber &&
                                                        "This data is not valid JSON."
                                                    }
                                                </Form.Text>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="align-items-end">
                    <Col>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>
                                    <strong>Template Definition</strong>
                                </Card.Title>
                                <Row className="align-items-end">
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <AceEditor
                                                style={{ border: !isTemplateValid ? "3px solid red" : "1px solid black" }}
                                                mode="javascript"
                                                theme="github"
                                                width="100%"
                                                fontSize={14}
                                                onChange={(code: any) => {
                                                    setHasFormDataChanged(true);
                                                    setTemplate({ ...template, definitionJson: code })
                                                }}
                                                name="templatedefinitionJson"
                                                value={template.definitionJson}
                                                showPrintMargin={true}
                                                showGutter={true}
                                                highlightActiveLine={true}
                                            />
                                            {
                                                !isTemplateValid &&
                                                <Form.Text style={{ color: "red" }}>
                                                    {
                                                        templateErrorLineNumber &&
                                                        `The JSON has an Error on or near line ${templateErrorLineNumber}`
                                                    }
                                                    {
                                                        !templateErrorLineNumber &&
                                                        "This data is not valid JSON."
                                                    }
                                                </Form.Text>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="align-items-end">
                    <Col sm={2}>
                        <Row className="p-1">
                            <Button
                                style={{ width: "100%" }}
                                variant="danger"
                                onClick={
                                    () => deleteAsync()}>Delete</Button>
                        </Row>
                        <Row className="p-1">
                            <Button
                                style={{ width: "100%" }}
                                variant="success"
                                onClick={
                                    () => cloneAsync()}>Clone to new form</Button>
                        </Row>
                    </Col>
                    <Col>
                        <Button
                            disabled={!isCustomWorkflowValid || !isTemplateValid}
                            style={{ width: "100%", height: "100%" }}
                            onClick={
                                () => submitFormAsync()}>Save Changes</Button>
                    </Col>
                </Row>
            </>
        );
    }
    else {
        return (
            <>
                <h1>There was an issue retrieving the template, please try again.</h1>
            </>
        );
    }
}

export default LabRequestTemplateDetail;
